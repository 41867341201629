import { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AuthenticateUser } from "../../services/restUserService";
import TextInput from "../dynamicForm/textInput/textInput";
import { useTranslation } from "react-i18next";

/**
 * Form to check if there's a user with a given email.
 *
 * @param {Object} props
 * @param props.onNewUser Callback function if this email is not registered.
 * @param props.onExistingUser Callback function if this email is registered.
 * @returns JSX
 */
const CheckEmailForm = (props) => {
  const {
    register: registerFormInput,
    handleSubmit,
    errors,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const [error, setError] = useState();
  const { t } = useTranslation();
  
  const checkEmail = async (input) => {
    const apiResponse = await AuthenticateUser(input.email);

    switch (apiResponse.headers.errorcode) {
      case '102':
        // The user is registered.
        props.onExistingUser(input);
        break;

      case '107':
        // New user – ask for more user data.
        props.onNewUser(input);
        break;

      default:
        setError(t(apiResponse.headers.errormessage));
        break;
    }
  };

  return (
    <form
      onSubmit={handleSubmit(checkEmail)}
      className="check-email-form col-sm-6 col-md-4 offset-sm-3 offset-md-4"
    >
      <div className="text-center my-3 font-weight-bold">
        { t('checkEmailForm.label') }
      </div>
      {error && <div className="alert alert-danger my-3">{error}</div>}
      <TextInput
        name="email"
        label={ t('checkEmailForm.placeholder') }
        type="email"
        required
        compact
        register={registerFormInput}
        errors={errors}
        onChange={null}
      />
      <div className="mt-3 text-center">
        <Button className="btn-danger px-4" variant="secondary" type="submit">
          { t('checkEmailForm.submit') }
        </Button>
      </div>
    </form>
  );
};

export default CheckEmailForm;
