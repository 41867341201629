import React, { useRef, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "./mapboxGL.scss";
import InfoMarker from "./infoMarker";

const addCustomPopup = (feature) => {
  return ReactDOMServer.renderToStaticMarkup(
    <InfoMarker
      marker={{
        Name: feature.properties.Name,
        Address1: feature.properties.Address1,
        City: feature.properties.City,
        StateAbbr: feature.properties.StateAbbr,
        PostCode: feature.properties.PostCode,
        CountryName: feature.properties.CountryName,
        Id: feature.properties.Id,
        Longitude: feature.geometry[0],
        Latitude: feature.geometry[1],
      }}
      useExternalLandingPageLink={true}
      showLandingPageLink={false}
      containerCss=""
    />
  );
};

const MapboxGL = ({
  features,
  customPin,
  mapboxToken,
  onMarkerClick,
  markerSelected = 0,
}) => {
  mapboxgl.accessToken = mapboxToken;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [currentSelectedMarker, setCurrentSelectedMarker] = useState(0);
  const [currentPopup, setCurrrentPopup] = useState();

  const boundsMap = () => {
    let bounds = new mapboxgl.LngLatBounds();
    // Add markers to the map.
    if (features?.length > 0) {
      features.forEach((feature) => {
        new mapboxgl.Marker(addMarker(feature, customPin, onMarkerClick))
          .setLngLat(feature.geometry.coordinates)
          // .setPopup(addCustomPopup(feature))
          .addTo(map.current);
        bounds.extend(feature.geometry.coordinates);
      });
      map.current.fitBounds(bounds, { padding: 100, animate: false });
    }
  };
  const addMarker = (feature, customPin, onMarkerClick) => {
    var el = document.createElement("div");
    el.className = "marker";

    el.style.backgroundImage = customPin
      ? `url(${customPin})`
      : "url(/images/beIcon.png)";

    el.style.width = feature.properties.iconSize[0] + "px";
    el.style.height = feature.properties.iconSize[1] + "px";
    el.style.backgroundSize = "100%";

    // this will return the ID to the parent
    if (onMarkerClick) {
      el.addEventListener("click", function () {
        console.log("Please record bullseye stadistics", feature.properties.Id);
        onMarkerClick(feature.properties.Id);
      });
    }

    return el;
  };

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center:
        features?.length > 0
          ? features[0].geometry.coordinates
          : [-95.712891, 37.09024],
      maxZoom: 15,
      // dragPan: false,
    });

    boundsMap();
    return () => map.current.remove();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  useEffect(() => {
    if (markerSelected > 0 && markerSelected !== currentSelectedMarker) {
      const marker = features.find((x) => x.properties.Id === markerSelected);
      map.current.panTo(marker.geometry.coordinates);
      if (currentPopup && currentSelectedMarker > 0) currentPopup.remove();

      const popup = new mapboxgl.Popup()
        .setLngLat(marker.geometry.coordinates)
        .setHTML(addCustomPopup(marker))
        .addTo(map.current);
      popup.on("close", function (e) {
        onMarkerClick(0);
      });
      setCurrrentPopup(popup);
      setCurrentSelectedMarker(markerSelected);
    } else {
      setCurrrentPopup(null);
      setCurrentSelectedMarker(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerSelected]);

  return <div ref={mapContainer} className="map-container" />;
};

export default MapboxGL;
