import React from "react";
import { useTranslation } from "react-i18next";
import { changeLanguageDates } from "../../utils/utils";

const LanguageSwitcher = ({ display = false }) => {
  const { i18n } = useTranslation();
  return (
    <>
      {display && (
        <div className="row my-1">
          <div className="col-12 d-flex align-items-end flex-column">
            <select
              style={{ maxWidth: 125 }}
              value={i18n.language}
              onChange={(e) => {
                i18n.changeLanguage(e.target.value);
                changeLanguageDates(e.target.value);
              }}
              className="form-control "
            >
              <option value="en">English</option>
              <option value="fr">French</option>
            </select>
          </div>
        </div>
      )}
    </>
  );
};
export default LanguageSwitcher;
