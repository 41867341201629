import _ from "lodash";
import admins from "../config.json";
import moment from "moment";
import "moment-timezone";
import "moment/locale/fr";
import "moment/locale/en-ca";

const bullseyeAdminURL = process.env.REACT_APP_ADMIN_URL;

const NewId = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

const FixRelativePath = function (imagePath) {
  if (!imagePath) return imagePath;

  let returnPath = imagePath;

  if (imagePath.toLowerCase().includes("~/resources/")) {
    returnPath = imagePath
      .toLowerCase()
      .replace("~/resources/", bullseyeAdminURL + "resources/");
  }

  // I did this because sometimes the server returns encode url and if I only apply the encodeURI
  // sometimes the urls are double encode
  return encodeURI(decodeURI(returnPath));
};

const splitListByColumns = function (
  originalList,
  numberOfColumns,
  screenSize
) {
  let numberColumns;
  let lists = [];

  switch (screenSize) {
    case "sm":
      numberColumns = originalList.length;
      break;
    case "md":
      numberColumns =
        numberOfColumns > 1 ? originalList.length / 2 : originalList.length;
      break;
    case "lg":
      numberColumns = originalList.length / numberOfColumns;
      break;

    default:
      numberColumns = originalList.length / numberOfColumns;
      break;
  }
  lists = _.chunk(originalList, Math.ceil(numberColumns));

  return lists;
};

const getStrListSplitByComma = function (originalList) {
  let strSplit = "";

  originalList.forEach((e) => {
    if (strSplit !== "") strSplit += ", ";
    strSplit += e.title;
  });
  return strSplit;
};
/** Get a category list from the collection of the JSON,  */
const getListCategoryFromCollectionByExistingCategories = function (
  collection,
  existingCategories
) {
  let returnList = [];
  collection
    .filter((e) => {
      return existingCategories.includes(e.id);
    })
    .forEach((item) => {
      returnList.push({ id: item.id, title: item.name });
    });

  return returnList;
};

function getLocationSocialMedia(socialMediaInfo) {
  const icons = [];

  if (!socialMediaInfo) return icons;

  if (socialMediaInfo.InstagramURL) {
    icons.push({
      name: "instagram",
      icon: "/images/instagram.svg",
      url: admins.instagramMainUrl + socialMediaInfo.InstagramURL,
    });
  }

  if (socialMediaInfo.LinkedInURL) {
    icons.push({
      name: "linkedin",
      icon: "/images/linkedin.svg",
      url: admins.LinkedInMainUrl + socialMediaInfo.LinkedInURL,
    });
  }

  if (socialMediaInfo.FacebookURL) {
    icons.push({
      name: "facebook",
      icon: "/images/facebook.svg",
      url: admins.facebookMainUrl + socialMediaInfo.FacebookURL,
    });
  }

  if (socialMediaInfo.TwitterURL) {
    icons.push({
      name: "twitter",
      icon: "/images/twitter.svg",
      url: admins.TwitterMainUrl + socialMediaInfo.TwitterURL,
    });
  }

  if (socialMediaInfo.PinterestURL) {
    icons.push({
      name: "pinterest",
      icon: "/images/pinterest.svg",
      url: admins.PinterestMainUrl + socialMediaInfo.PinterestURL,
    });
  }

  if (socialMediaInfo.YelpURL) {
    icons.push({
      name: "yelp",
      icon: "/images/yelp.svg",
      url: admins.YelpMainUrl + socialMediaInfo.YelpURL,
    });
  }
  return icons;
}

const documentIcon = (
  <svg className="mr-2" width="16" height="16">
    <use xlinkHref="/images/b-icons/bootstrap-icons.svg#file-earmark-text-fill" />
  </svg>
);

function getStateNameByAbbr(name, region1List) {
  var result = [];
  if (name && region1List) {
    result = region1List.filter(
      (o) => o.Abbr.toLowerCase() === name.toLowerCase()
    );
  }
  return result.length > 0 ? result[0].Name : null; // or undefined
}

function getStateAndCity(state, city, region1List) {
  let content;

  if (state && city) {
    content = `${city},${state}`;
  } else if (state && !city) {
    content = getStateNameByAbbr(state, region1List);
  } else {
    content = "";
  }
  return content;
}

//Convert hours that is in AM/PM to 24H Format
function convertTimeFrom12To24Format(time12) {
  const time12Normalized = time12.toUpperCase();
  const [sHours, minutes, period] = time12Normalized
    .match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/)
    .slice(1);
  const PM = period === "PM";
  const hours = (+sHours % 12) + (PM ? 12 : 0);

  return `${("0" + hours).slice(-2)}:${minutes}`;
}

function convertTimeFrom24To12Format(time24) {
  const [hours, minutes] = time24.split(":");
  const h = hours % 12 || 12;
  const ampm = hours < 12 || hours === 24 ? "AM" : "PM";
  return `${h}:${minutes} ${ampm}`;
}

const convertBullseyeDateToISO = (dateString) => {
  // Split the input date string into components
  const [month, day, year] = dateString.split('/');

  // Return the date in 'YYYY-MM-DD' format
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

const capitalizeFirstLetter = (string) => {
  if (!string) return "";

  let textToConvertFirstLetter = string.charAt(0).toUpperCase();
  return textToConvertFirstLetter + string.toLowerCase().slice(1);
};

const getCloseInformation = (daysArray, TimeZone) => {
  let result = null;
  if (daysArray && daysArray.length > 0) {
    //console.log('daysArray',daysArray)
    //Get Next and currect time

    let finalDaysArray = daysArray.filter((x) => x.NoHoursAvailable === false);
    const currentCloseDateTime = finalDaysArray[0];
    const nextDayCloseDateTime = finalDaysArray[1];

    //Get current day close time
    let currentDayTimeClose = null;
    if (currentCloseDateTime && currentCloseDateTime.HoursDisplayText) {
      currentDayTimeClose = currentCloseDateTime.HoursDisplayText.substring(
        currentCloseDateTime.HoursDisplayText.indexOf("-") + 2
      );
    }

    //Get next day open time
    let nextDayOpenTime = null;
    if (nextDayCloseDateTime && nextDayCloseDateTime.HoursDisplayText) {
      nextDayOpenTime = nextDayCloseDateTime.HoursDisplayText.substring(
        0,
        nextDayCloseDateTime.HoursDisplayText.indexOf("-") - 1
      );
    }

    //Get TimeZone Hours (GTM-6) from string
    let TimeZoneHours = null;
    if (TimeZone) {
      TimeZoneHours = TimeZone.substring(
        TimeZone.indexOf("-"),
        TimeZone.indexOf(")")
      );
    }

    //Get CurrentTime in UTC With Time Offset
    let currentDateTime = moment()
      .utc()
      .utcOffset(TimeZoneHours)
      .format("YYYY-MM-DD HH:mm:ss");
    //console.log('currentDateTime',currentDateTime,'TimeZoneHours',TimeZoneHours, 'WithoutTimezone', moment(moment()).utc().format("YYYY-MM-DD HH:MM:ss"));

    let closeDateTime = moment(
      moment().format("YYYY-MM-DD") +
        " " +
        convertTimeFrom12To24Format(currentDayTimeClose)
    ).format("YYYY-MM-DD HH:mm:ss");

    let finalcurrentDateTime = moment(currentDateTime);
    let finalcloseDateTime = moment(closeDateTime);

    //Diference Between two Dates in Hours
    const diffHours = finalcloseDateTime.diff(finalcurrentDateTime, "hours");

    //Diference Between two Dates in Minutes
    const diffMinutes = finalcloseDateTime.diff(
      finalcurrentDateTime,
      "minutes"
    );

    let todayNextDay = finalcurrentDateTime.add(1, "d");
    // console.log('currentDateTime',currentDateTime,'finalcurrentDateTime',finalcurrentDateTime,'finalcloseDateTime',finalcloseDateTime,'closeDateTime',closeDateTime,'currentDayTimeClose',currentDayTimeClose,'diffHours',diffHours,'diffMinutes',diffMinutes,'nextDayOpenTime',nextDayOpenTime);

    if (
      diffHours > 0 &&
      todayNextDay.format("dddd").toLowerCase() ===
        nextDayCloseDateTime.NameOfDay.toLowerCase()
    ) {
      result = `Open today until ${currentDayTimeClose}`;
    } else if (
      diffHours === 0 &&
      diffMinutes > 0 &&
      todayNextDay.format("dddd").toLowerCase() ===
        nextDayCloseDateTime.NameOfDay.toLowerCase()
    ) {
      result = `Close in ${diffMinutes} min`;
    } else if (
      !nextDayCloseDateTime.NoHoursAvailable &&
      todayNextDay.format("dddd").toLowerCase() ===
        nextDayCloseDateTime.NameOfDay.toLowerCase()
    ) {
      result = `Closed until tomorrow at ${nextDayOpenTime}`;
    } else if (
      todayNextDay.format("dddd").toLowerCase() !==
      nextDayCloseDateTime.NameOfDay.toLowerCase()
    ) {
      result = `Closed until ${capitalizeFirstLetter(
        nextDayCloseDateTime.NameOfDay.toLowerCase()
      )} at ${nextDayOpenTime}`;
    } else {
      result = "Closed";
    }
  }

  return result;
};

function dateToStringFormat(date, format) {
  let newDate = moment(date); //.add(1, "d");
  return newDate.format(format);
}
function getDaysAgoFromToday(date) {
  const day = moment(date);
  return day.fromNow();
}
function sortArrayReviewsByDateDesc(oldReviews) {
  return oldReviews.sort(function (left, right) {
    return moment(right.DateCreated).diff(moment(left.DateCreated));
  });
}
function datesAreEquals(date1, date2) {
  let newDate1 = moment(date1);
  let newDate2 = moment(date2);
  return newDate1.format("mm/dd/yyyy") === newDate2.format("mm/dd/yyyy");
}
function date1IsEqualsOrGreaterThanDate2(date1, date2) {
  let newDate1 = moment(date1);
  let newDate2 = moment(date2);

  return newDate1 >= newDate2;
}
function sortArrayYelpReviewsByDateDesc(oldReviews) {
  return oldReviews.sort(function (left, right) {
    return moment(right.RelativeTime).diff(moment(left.RelativeTime));
  });
}
function isValidPostalCode(postalCode, countryCode) {
  let postalCodeRegex;
  switch (countryCode) {
    case "US":
      postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
      break;
    case "CA":
      postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
      break;
    default:
      postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  }
  return postalCodeRegex.test(postalCode);
}
const getUrlParameterValue = (sParam) => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};
function changeLanguageDates(language) {
  moment.locale(
    language.toLocaleLowerCase().replace("-ca", "").replace("-us", "") === "fr"
      ? "fr"
      : "en-ca"
  );
}
export function getReturnUrl(listParams) {
  const urlParams = window.location.search;
  let paramsIframe = new URLSearchParams(urlParams);
  // const distanceQS = params.get("distance");
  let url = paramsIframe.get("urlR");
  let params = "?";

  if (!url && window.location !== window.parent.location) {
    //if (window.parent.document.referrer) url = window.parent.document.referrer;
    //else
    if (window.document.referrer) url = window.document.referrer;
    else if (window.parent.location) url = window.parent.location.href;
    else url = document.location.href;
  }
  listParams.forEach((x, index) => {
    if (index === 0) params = "?";
    params += `${index > 0 ? "&" : ""}${x}`;
  });

  url = url?.endsWith("/") ? url.slice(0, -1) : url;
  url = url?.includes("?") ? url.split("?")[0] : url;
  url = url?.includes("&") ? url.split("&")[0] : url;
  url !== null && (params += "&urlR=" + url);

  return params;
}

export {
  NewId,
  FixRelativePath,
  splitListByColumns,
  getStrListSplitByComma,
  getListCategoryFromCollectionByExistingCategories,
  getLocationSocialMedia,
  getStateNameByAbbr,
  getStateAndCity,
  getCloseInformation,
  capitalizeFirstLetter,
  dateToStringFormat,
  getDaysAgoFromToday,
  sortArrayReviewsByDateDesc,
  datesAreEquals,
  sortArrayYelpReviewsByDateDesc,
  documentIcon,
  isValidPostalCode,
  date1IsEqualsOrGreaterThanDate2,
  getUrlParameterValue,
  changeLanguageDates,
  convertTimeFrom12To24Format,
  convertTimeFrom24To12Format,
  convertBullseyeDateToISO,
};
