import React from "react";

const CardWrapper = ({ children, paddingClass = "p-3" }) => {
  return (
    <div className={["card shadow-sm mt-3", paddingClass].join(" ")}>
      {children}
    </div>
  );
};

export default CardWrapper;
