import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./breadcrumbMenu.scss";

import PropTypes from "prop-types";
/**
 * Component build a breadCrum menu.
 */
const BreadcrumbMenu = ({ items }) => {
  return (
    <Breadcrumb>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item?.isExternalLink && (
            <Breadcrumb.Item
              href={item.url}
              active={items.length === index + 1}
            >
              {item.displayText}
            </Breadcrumb.Item>
          )}
          {!item.isExternalLink && (
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: item.url }}
              active={items.length === index + 1}
            >
              {item.displayText}
            </Breadcrumb.Item>
          )}
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};
BreadcrumbMenu.propTypes = {
  /** List of menu items with at least the following attributes */
  items: PropTypes.arrayOf(
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** option to know where to url return  */
      url: PropTypes.string,
      /** Text to display on the menu */
      displayText: PropTypes.string.isRequired,
    })
  ),
};

export default BreadcrumbMenu;
