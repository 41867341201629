import { useTranslation } from "react-i18next";

export default function DefaultPage() {
  const { t } = useTranslation();

  return (
    <a
      href={'/formLead' + window.location.search}
      className="bullseye-red"
    >
      {t("locator.btn_submitCelebration")}
    </a>
  );
}
