import http from "./httpService";

const endpointPath = "RestUser.svc";

/**
 * Checks if the provided credentials can authenticate a Bullseye user.
 * 
 * @param {string} username
 * @param {string} password
 * @returns {boolean} Authentication status: true/false
 */
export async function AuthenticateUser(username, password) {
  http.setSecurityParameters2();
  const config = {params: {username, password}};
  return await http.get(`${endpointPath}/AuthenticateUser`, config);
}
