import React from "react";
import { useTracking } from "react-tracking";
import "./googleMap.scss";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const InfoMarker = ({ marker, onClose, showLandingPageLink }) => {
  const {
    Name,
    Address1,
    City,
    StateAbbr,
    State,
    PostCode,
    CountryName,
    URL,
    Id,
  } = marker;
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  return (
    <>
      <div className="card infoWindow">
        <div className="card-header text-right bg-white p-0">
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="card-body p-0 mt-n2">
          <div className="gm-style gm-style-iw">
            <h6>
              {showLandingPageLink && URL ? <Link to={URL}>{Name}</Link> : Name}
            </h6>
            <div id="bodyContent">
              <ul className="list-unstyled mb-0">
                <li>{Address1}</li>
                <li>{`${City}, ${State ?? StateAbbr} ${PostCode}`}</li>
                <li>{CountryName}</li>
                <li>
                  <hr className="my-2" />
                </li>
                <li>
                  <a
                    onClick={() => {
                      trackEvent({
                        eventType: EVENT_LOG_TYPE.logSearch,
                        locationId: Id,
                        responseType: SEARCH_RES_LOG_TYPE.directionsClicked,
                      });
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={`https://maps.google.com/maps?saddr=&daddr=${Address1} ${City} ${
                      StateAbbr || ""
                    } ${PostCode} ${CountryName || ""}`}
                  >
                    {t("map.label_directions")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="arrow" />
      </div>
    </>
  );
};
InfoMarker.propTypes = {
  /** Location Information with at least the following attributes */
  marker:
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** location Id */
      Id: PropTypes.number.isRequired,
      /** coords of location */
      Latitude: PropTypes.number.isRequired,
      Longitude: PropTypes.number.isRequired,
      /** name of the location */
      Name: PropTypes.string.isRequired,
      /** address of the location */
      Address1: PropTypes.string.isRequired,
      /** city of the address */
      City: PropTypes.string.isRequired,
      /** state of city */
      State: PropTypes.string,
      /** postal code of the location */
      PostCode: PropTypes.string,
      /** country of this location */
      Country: PropTypes.string,
      /** url of the lading page detail of this location */
      URL: PropTypes.string,
    }).isRequired,
  /** function to on click on one item to the list will be selected into the parent */
  onClose: PropTypes.func,
  /** flag to show on the InfoWindow the name as a link or not, this will be used to redirect to a Location landing page  */
  showLandingPageLink: PropTypes.bool.isRequired,
};

export default InfoMarker;
