import React from "react";
import { Link } from "react-router-dom";
import { EVENT_LOG_TYPE, SEARCH_RES_LOG_TYPE } from "../../utils/constants";
import Address from "../address/address";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
const LocationInformation = ({
  locationId,
  address1,
  address2,
  city,
  stateAbbr,
  postalCode,
  country,
  phone,
  locationName,
  contactName,
  contactPosition,
  landingPageURL,
  distance,
  email,
  websiteUrl,
  title,
  date,
  showDirection = true,
}) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex">
        <div className="flex-fill">
          {title && <div className="bullseye-red h4">{title}</div>}
          {landingPageURL ? (
            <Link
              to={landingPageURL}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                trackEvent({
                  eventType: EVENT_LOG_TYPE.logSearch,
                  locationId: locationId,
                  responseType: SEARCH_RES_LOG_TYPE.urlClicked,
                });
              }}
            >
              <span className="h5 d-inline-block">{locationName}</span>
            </Link>
          ) : (
            <span className="h6">{locationName}</span>
          )}
        </div>
        <div>
          {(distance && distance > -1) || distance === 0 ? (
            <span>{distance}&nbsp;km</span>
          ) : (
            ""
          )}
        </div>
      </div>

      <Address
        address1={address1}
        address2={address2}
        city={city}
        stateAbbr={stateAbbr}
        postalCode={postalCode}
        country={country}
        phone={phone}
      ></Address>

      <div className="date-time mb-2 mt-n1">{date}</div>

      {contactName && (
        <span>
          <b>Contact</b> {contactName}{" "}
          {contactPosition && <>, {contactPosition}</>}
        </span>
      )}
      {email && (
        <span className="d-block">
          <b>
            <a
              href={`mailto:${email}`}
              onClick={() => {
                trackEvent({
                  eventType: EVENT_LOG_TYPE.logSearch,
                  locationId: locationId,
                  responseType: SEARCH_RES_LOG_TYPE.emailClicked,
                });
              }}
            >
              {t("email")}
            </a>
          </b>
        </span>
      )}
      {websiteUrl && (
        <span className="d-block">
          <b>
            <a
              href={websiteUrl}
              target="_blank"
              onClick={() => {
                trackEvent({
                  eventType: EVENT_LOG_TYPE.logSearch,
                  locationId: locationId,
                  responseType: SEARCH_RES_LOG_TYPE.directionsClicked,
                });
              }} rel="noreferrer"
            >
              Website
            </a>
          </b>
        </span>
      )}
      {showDirection && !landingPageURL && address1 && city && (
        <span className="d-block">
          <b>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://maps.google.com/maps?saddr=&daddr=${address1} ${city} ${
                stateAbbr || ""
              } ${postalCode} ${country || ""}`}
              onClick={() => {
                trackEvent({
                  eventType: EVENT_LOG_TYPE.logSearch,
                  locationId: locationId,
                  responseType: SEARCH_RES_LOG_TYPE.directionsClicked,
                });
              }}
            >
              {t("map.label_directions")}
            </a>
          </b>
        </span>
      )}
      {landingPageURL && (
        <Link
          to={landingPageURL}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            trackEvent({
              eventType: EVENT_LOG_TYPE.logSearch,
              locationId: locationId,
              responseType: SEARCH_RES_LOG_TYPE.urlClicked,
            });
          }}
        >
          <span className="d-inline-block">{t("results.label_details")}</span>
        </Link>
      )}
    </>
  );
};

export default LocationInformation;
