export const PATTERNS_VALIDATIONS_LOCATOR = {
  postalCodeUS: /([0-9]{5})(?:[-\s]*([0-9]{4}))?$/,
  postalCodeCA: /([A-Za-z][0-9][A-Za-z])\s*([0-9][A-Za-z][0-9])$/,
  postalCode: /(?:[A-Za-z0-9]+([- ]?[A-Za-z0-9]+)*)?$/,
  cityCommaOrSpaceState: /([a-z]+([, ]?[A-Za-z]{2})?)$/,
  citySpaceState: /([a-z]+([ ]?[A-Za-z]{2})?)$/,
};
export const MAP_TYPES = {
  googleDefault: 1,
  mapBox: 2,
  google: 3,
};
export const INPUTS_VALIDATIONS_PATTERNS = {
  // eslint-disable-next-line no-useless-escape
  url: /(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
  imageURL: /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  //password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
  password:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\.@$!%*#?&])[A-Za-z\d\.@$!%*#?&]{8,}$/,
  // imageURLPattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+(?:png|jpg|jpeg|gif|svg)+$/g,
};
export const REVIEW_TYPES = {
  bullseye: "BEReviews",
  google: "Google",
  yelp: "Yelp",
};
export const COUNTRIES_LIST_IDS = {
  USA: 1,
  CANADA: 2,
  UK: 3,
};

export const SEARCH_RES_LOG_TYPE = {
  urlClicked: 1,
  emailClicked: 2,
  locationResultsClicked: 3,
  locationMapClicked: 4,
  directionsClicked: 5,
  promotionPrint: 6,
  promotionShare: 7,
  locationPromotionPrint: 8,
  attributeClicked: 9,
};

export const EVENT_LOG_TYPE = {
  logSearch: 1,
};

export const formReviewConfiguration = {
  fields: [
    {
      id: 1,
      inputOptionName: "text",
      name: "FirstName",
      label: "First Name",
      validations: [
        {
          boolValue: true,
          errorMessage: "Name is required.",
          rule: "isRequired",
        },
      ],
    },
    {
      id: 2,
      inputOptionName: "text",
      name: "LastName",
      label: "Last Name",
      validations: [
        {
          boolValue: true,
          errorMessage: "Last Name is required.",
          rule: "isRequired",
        },
      ],
    },
    {
      id: 3,
      inputOptionName: "rate",
      name: "OverallRating",
      label: "Rate it!",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Rate is required.",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Rate is required.",
          rule: "min",
        },
      ],
    },
    {
      id: 8,
      inputOptionName: "checkboxlist",
      name: "RecommendationQuestion",
      label: "",
      options: [
        {
          id: 1,
          name: "Yes, I recomend this location",
          value: 1,
        },
      ],
      validations: [],
    },
    {
      id: 4,
      inputOptionName: "Comments",
      name: "Comments",
      placeholder: "Add your comments",
      label: "Comments",
      validations: [
        {
          boolValue: true,
          errorMessage: "Commets are required.",
          rule: "isRequired",
        },
      ],
    },
    {
      id: 5,
      inputOptionName: "rateService",
      name: "RatingCategoryOne",
      label: "Professionalism",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Professionalism is required.",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Professionalism is required.",
          rule: "min",
        },
      ],
    },
    {
      id: 6,
      inputOptionName: "rateService",
      name: "RatingCategoryTwo",
      label: "Product Knowledge",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Product Knowledge is required.",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Product Knowledge is required.",
          rule: "min",
        },
      ],
    },
    {
      id: 7,
      inputOptionName: "rateService",
      name: "RatingCategoryThree",
      label: "Service",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Service is required.",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Service is required.",
          rule: "min",
        },
      ],
    },
  ],
};

export const LOCATION_STATUS = {
  notCompleted: 1,
  pending: 2,
  approved: 3,
  rejected: 4,
  deactivated: 5,
  updated: 6
}