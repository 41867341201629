import React from "react";

const ErrorDisplay = ({ errorMessage, isAllCols = false }) => {
  return (
    <>
      {errorMessage && (
        <div className="d-flex flex-row-reverse w-100">
          <div
            className={`${
              isAllCols ? "col-12" : "col-sm-8"
            } text-left invalid-feedback d-block`}
          >
            {errorMessage}
          </div>
        </div>
      )}
    </>
  );
};
export default ErrorDisplay;
