import React, { useState, useEffect, useRef } from "react";
import MasonryGallery from "../masonryGallery/masonryGallery";
import Carousel from "react-multi-carousel";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "./galleryImages.scss";
import "lazysizes";

/**
 * Component to show a masonry gallery images
 */
const GalleryImages = ({ title, photos, columns = 4 }) => {
  const [currentImage, setCurrentImage] = useState(10);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  const carouselRef = useRef(null);

  useEffect(() => {
    /** goToSlide when the CurrentImage changes */
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goToSlide(currentImage);
    }
  }, [currentImage]);

  const breakpointColumnsObj = {
    default: 3,
    3000: 4,
    1140: 4,
    960: 3,
    720: 2,
    540: 1,
  };

  return (
    <>
      <div className="bullseye-red h4">{title}</div>
      <div>
        <MasonryGallery
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {photos.map((image, index) => (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              className="lazyload w-100 d-block"
              onClick={(e) => openLightbox(index)}
              key={index}
              data-src={image.imageURL}
              alt={image?.alternativeText}
            />
          ))}
        </MasonryGallery>

        <Modal
          animation={true}
          show={viewerIsOpen}
          onHide={closeLightbox}
          centered
          contentClassName="galleryImage__modal--content"
        >
          <Modal.Header className="galleryImage__modal--header">
            <div className="float-right">
              <Button onClick={closeLightbox}>X</Button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Carousel
              ref={carouselRef}
              arrows={true}
              additionalTransfrom={0}
              showDots={false}
              autoPlaySpeed={3000}
              centerMode={false}
              keyBoardControl
              itemClass="galleryImage__carousel--item d-flex align-items-center justify-content-center"
              responsive={responsive}
            >
              {photos.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      className="img-fluid"
                      src={item.imageURL}
                      alt={item.alternativeText}
                    />
                  </div>
                );
              })}
            </Carousel>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
GalleryImages.propTypes = {
  /** Title to display on the header component */
  title: PropTypes.string.isRequired,
  /**Number of the columns to display the gallery, default = 3*/
  columns: PropTypes.number,
  /** object with the styles of the columns */
  columnsCount: PropTypes.object,
  /** Array of images to display on the gallery */
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      /** source path of the image */
      imageURL: PropTypes.string.isRequired,
      /** text to show on the modal about the image */
      alternativeText: PropTypes.string,
    })
  ),
};

export default GalleryImages;
