import React, { useEffect, useState } from "react";
import TextInput from "../textInput";
import DropDownInput from "../dropDownInput";
import { useTranslation } from "react-i18next";
const AddressInput = ({
  register,
  compact = false,
  small = false,
  errors,
  onChange,
  countriesAndStatesConfig,
}) => {
  const [countriesList, setCountriesList] = useState([]);
  const [addressValues, setAddressValues] = useState({
    countryId: undefined,
    isVisibleState: true,
    labelState: "State",
    labelZipCode: "Zip Code",
    statesList: [],
  });
  useEffect(() => {
    if (countriesAndStatesConfig?.countries?.length > 0) {
      setCountriesList(countriesAndStatesConfig.countries);
      let listStates = countriesAndStatesConfig.states.find(
        (x) => x.countryId === countriesAndStatesConfig.countries[0].value
      );
      let listLabels = countriesAndStatesConfig.labels.find(
        (x) => x.countryId === countriesAndStatesConfig.countries[0].value
      );

      setAddressValues({
        countryId: countriesAndStatesConfig.countries[0].value,
        isVisibleState: listLabels.visibleState,
        labelState: listLabels.labelState,
        labelZipCode: listLabels.labelZipCode,
        statesList: listStates.list,
      });
    }
  }, [countriesAndStatesConfig]);

  const chagingStates = (CountryId) => {
    let statesList, lbState, visibleState, lbZipCode;

    let countryListStates = countriesAndStatesConfig.states.find(
      (x) => x.countryId === parseInt(CountryId)
    );

    let countryConfig = countriesAndStatesConfig.labels.find(
      (x) => x.countryId === parseInt(CountryId)
    );
    if (countryConfig) {
      statesList = [...countryListStates.list];
      lbState = countryConfig.labelState;
      visibleState = countryConfig.visibleState;
      lbZipCode = countryConfig.labelZipCode;
    } else {
      // if no country with states, take the default config
      countryConfig = countriesAndStatesConfig.labels.find(
        (x) => x.countryId === 0
      );
      statesList = [];
      lbState = countryConfig.labelState;
      visibleState = countryConfig.visibleState;
      lbZipCode = countryConfig.labelZipCode;
    }
    setAddressValues({
      postalCodePattern: /\d{5}/,
      countryId: CountryId,
      isVisibleState: visibleState,
      labelState: lbState,
      labelZipCode: lbZipCode,
      statesList: statesList,
    });
  };

  const changeCountry = (e) => {
    console.log('changeCountry', e.target.value);
    setAddressValues({countryId: e.target.value, ...addressValues});
    chagingStates(e.target.value);
    onChange(e);
  };
  const { t } = useTranslation();

  const selectedCountry = countriesAndStatesConfig.countries.find(c => {
    return parseInt(c.value) === parseInt(addressValues.countryId)
  });

  return (
    <>
      {countriesAndStatesConfig?.countries?.length > 0 && (
        <>
          {countriesAndStatesConfig?.countries?.length > 1 && (
            <DropDownInput
              label={t("formLead.label_country")}
              name={"CountryId"}
              small={small}
              options={[[], ...countriesList]}
              register={register({
                required: t("formLead.label_countryIsRequired"),
              })}
              required={true}
              errors={errors}
              onChange={changeCountry}
            />
          )}
          {countriesAndStatesConfig?.countries?.length === 1 && (
            <TextInput
              name={"CountryId"}
              type={"hidden"}
              label={""}
              compact={true}
              small={true}
              value={countriesAndStatesConfig.countries[0].value}
              register={register({
                required: t("formLead.label_countryIsRequired"),
              })}
              required={true}
              errors={errors}
              onChange={onChange}
            />
          )}

          <TextInput
            name={"Address1"}
            type={"text"}
            label={t("formLead.label_streetAddress")}
            compact={compact}
            small={small}
            register={register({
              required: t("formLead.label_streetAddressIsRequired"),
            })}
            required={true}
            errors={errors}
            onChange={onChange}
          />
          <TextInput
            name={"Address2"}
            type={"text"}
            label={t("formLead.label_address2")}
            compact={compact}
            small={small}
            register={register}
            required={false}
            errors={errors}
            onChange={onChange}
          />
          <TextInput
            name={"City"}
            type={"text"}
            label={t("formLead.label_city")}
            compact={compact}
            small={small}
            register={register({
              required: t("formLead.label_cityIsRequired"),
            })}
            required={true}
            errors={errors}
            onChange={onChange}
          />
          {addressValues.isVisibleState && addressValues.statesList && (
            <DropDownInput
              label={t("formLead.label_provice")}
              name={"StateAbbr"}
              small={small}
              options={[[], ...addressValues.statesList]}
              register={register}
              required={true}
              errors={errors}
              onChange={onChange}
            />
          )}
          <TextInput
            name={"PostalCode"}
            type={"text"}
            label={t("formLead.label_postalCode")}
            compact={compact}
            small={small}
            register={register({
              pattern: selectedCountry?.postalCodePattern,
              required: t("formLead.label_postalCodeIsRequired"),
            })}
            required={true}
            errors={errors}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};

export default AddressInput;
