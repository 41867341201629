import React from "react";
import "./cardWithImage.scss";
import PropTypes from "prop-types";
/** simple component to show a list with text body and image to the left
 */
const CardWithImage = ({ title, body, image }) => {
  return (
    <div>
      <div className="bullseye-red h4">{title}</div>
      <div>
        {image && (
          <img
            className="cardWithImage__img--maxSize m-2 float-left"
            src={image}
            alt={title}
          ></img>
        )}
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      </div>
    </div>
  );
};
CardWithImage.propTypes = {
  /** Title to display on the card */
  title: PropTypes.string.isRequired,
  /** Text body to display */
  body: PropTypes.string,
  /** Image url to show on the card */
  image: PropTypes.string,
};
export default CardWithImage;
