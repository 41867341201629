import React, { useEffect, useState } from "react";

/** Components */
import BullseyeLoader from "../bullseyeLoader";

import FormLead from "../formLead/formLead";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";

import { useTranslation } from "react-i18next";

/** Styles */
import "./listMapLocator.scss";
import { getInterfaceCongif } from "../../services/restInterfaceConfiguration";

import BreadcrumbMenu from "../breadcrumbMenu/breadcrumbMenu";

const FormLeadPage = (props) => {
  const [locatorConfiguration, setLocatorConfiguration] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    /** get the configuration for the locator */
    const getLocatorConfiguration = async () => {
      const config = await getInterfaceCongif(
        process.env.REACT_APP_INTERFACE_NAME,
        process.env.REACT_APP_API_KEY_NAME,
        i18n.language
      );
      setLocatorConfiguration(config);
    };
    getLocatorConfiguration();
  }, [i18n.language]);

  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const urlReturn = params.get("urlR");

  return (
    <>
      <div className="container-fluid container-lg">
        <LanguageSwitcher />
        <div className="card my-2" style={{ width: "15rem", border: "0px" }}>
          <img
            className="card-img-top b-0"
            src="/images/logo.png"
            alt="Legion"
          />
        </div>
        <div className="row">
          <BreadcrumbMenu
            items={[
              {
                url: t("locatorURL"),
                displayText: t("landingPage.breadcrumLocator"),
                isExternalLink: true,
              },
              { displayText: t("formLead.label_breadcrumTitle") },
            ]}
          />
        </div>
        <div className="row my-5">
          <div className="col-12 d-block ">
            <div className="card">
              <div className="card-body d-block">
                { locatorConfiguration.clientId
                  ? <FormLead
                    title="Ceremony Submission"
                    small={true}
                    compact={false}
                    GoogleCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                    locatorConfiguration={locatorConfiguration}
                    ClientId={locatorConfiguration.clientId}
                    ApiKey={locatorConfiguration.apiKey}
                    countriesConfig={locatorConfiguration.countries}
                  />
                  : <BullseyeLoader />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLeadPage;
