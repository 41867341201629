import { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AuthenticateUser } from "../../services/restUserService";
import DynamicInputSelector from "../dynamicForm/dynamicInputSelector";
import ValidationsGenerator from "../dynamicForm/validationGenerator";
import { useTranslation } from "react-i18next";

/**
 * Form component to check if there's a user with a given email.
 *
 * @param {Object} props
 * @param props.data Default form data:
 * @param props.data.email
 * @param props.data.password
 * @param props.onLogin Callback function upon successful login.
 * @returns JSX
 */
const RegistrationForm = (props) => {
  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const [error, setError] = useState();

  const checkCredentials = async (input) => {
    const apiResponse = await AuthenticateUser(input.email, input.password);

    switch (apiResponse.headers.errorcode) {
      case "108":
        // A new user can be created.
        props.onSubmit(input);
        break;

      default:
        setError(apiResponse.headers.errormessage);
        break;
    }
  };

  const defaultValues = { email: null, password: null, ...props.data };

  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(checkCredentials)}
      className="login-form col-sm-6 offset-sm-3"
    >
      <div className="text-center mb-4 font-weight-bold">
        { t("registrationForm.label") }
      </div>
      {error && <div className="alert alert-danger my-3">{error}</div>}
      <DynamicInputSelector
        name="email"
        label={ t("registrationForm.email") }
        required
        inputOptionName="email"
        validations={ValidationsGenerator({
          validations: [
            {
              boolValue: true,
              errorMessage: t("registrationForm.errors.invalidEmail"),
              rule: "isEmail",
            },
            {
              boolValue: true,
              errorMessage: t("registrationForm.errors.requiredField"),
              rule: "isRequired",
            },
          ],
        })}
        register={register}
        errors={errors}
        onChange={null}
        defaultValue={defaultValues.email}
      />
      <DynamicInputSelector
        name="name"
        label={ t("registrationForm.name") }
        required
        inputOptionName="text"
        validations={ValidationsGenerator({
          required: true, validations: [
            {
              boolValue: true,
              errorMessage: t("registrationForm.errors.requiredField"),
              rule: "isRequired",
            },
          ]
        })}
        register={register}
        errors={errors}
        onChange={null}
        defaultValue={defaultValues.name}
      />
      <DynamicInputSelector
        name="password"
        label={ t("registrationForm.password") }
        required
        inputOptionName="password"
        validations={ValidationsGenerator({
          isPassword: true,
          required: true,
          validations: [
            {
              boolValue: true,
              errorMessage: t("registrationForm.errors.requiredField"),
              rule: "isRequired",
            },
          ],
        })}
        register={register}
        errors={errors}
        onChange={() => {}}
        defaultValue={defaultValues.name}
      />
      <div className="row">
        <div className="mt-1 text-center-sm col-sm-6 offset-sm-4 text-center text-sm-left">
          <Button className="btn-danger px-4" variant="secondary" type="submit">
            { t("registrationForm.submit") }
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RegistrationForm;
