import React, { createContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import AppForm from "./components/pages/formLeadPage";
import GetParameterPage from "./components/pages/getParameterPage";
import fakeLandingConfig from "./services/json/sampleDataLandingPage.json";
import { setSecurityParameters } from "./services/httpService";
import { LogSearchRsltwReq } from "./services/restSearchService";
import track from "react-tracking";
import { getInterfaceCongif } from "./services/restInterfaceConfiguration";
import BullseyeLoader from "./components/bullseyeLoader/bullseyeLoader";
import DefaultPage from "./components/pages/defaultPage";

export const BullseyeContext = createContext();

function App2() {
  const [locatorConfiguration, setLocatorConfiguration] = useState();
  useEffect(() => {
    const getLocatorConfiguration = async () => {
      const config = await getInterfaceCongif(
        process.env.REACT_APP_INTERFACE_NAME,
        process.env.REACT_APP_API_KEY_NAME
      );
      setLocatorConfiguration(config);
    };
    getLocatorConfiguration();
  }, []);

  const [landingPageConfiguration, setLandingPageConfiguration] = useState({
    loading: true,
    config: {
      landingPageId: 0,
      templateCssURLPath: null,
      advancedStyleCssPath: null,
      blocks: [],
      landingPageShowcaseContent: [],
      apiKey: null,
      reviewType: "",
      clientId: 0,
      mapIconURL: null,
    },
  });
  
  useEffect(() => {
    const setLandingPageConfig = async () => {
      const response = fakeLandingConfig;
      // We need to manage sprites on svg to support color change
      // We will use local svg for benefits in the future maybe we
      // need to change this and build better solution
      if (response.benefits?.length > 0) {
        response.benefits = response.benefits.map((benefit) => {
          if (!benefit.iconName && benefit.iconUrl) return benefit;
          benefit.iconUrl =
            "/images/benefits/benefit_icons.svg#" + benefit.iconName; // 😒 I don't like this hardcoding path

          // the result of each icon should be something like this /images/benefits/benefit_icons.svg#benefit_lightbulb
          return benefit;
        });
      }

      setSecurityParameters(response.clientId, response.apiKey);
      setLandingPageConfiguration({ loading: false, config: response });
    };

    setLandingPageConfig();
  }, []);

  const bullseyeContextValue = {
    locatorConfiguration: locatorConfiguration,
    landingPageConfiguration: landingPageConfiguration?.config
  }

  return (
    <>
      <BullseyeContext.Provider value={bullseyeContextValue}>
      { landingPageConfiguration !== null &&
        !landingPageConfiguration.loading &&
        locatorConfiguration !== null
        ? <>
          <ToastContainer />
          <Switch>
            <Route path="/landing/:interfaceName/:slug-:location(\d+)">
              <GetParameterPage 
                landingPageConfiguration={landingPageConfiguration} 
                locatorConfiguration={locatorConfiguration}
              />
            </Route>
            <Route path="/formlead" component={AppForm} />
            <Route path="/" component={DefaultPage} />
          </Switch>
        </>
        : <BullseyeLoader />
      }
      </BullseyeContext.Provider>
    </>
  );
}

export default track(
  { app: "Legion-Locator" },
  {
    dispatch: (data) => {
      LogSearchRsltwReq(
        data.locationId,
        data.responseType,
        data.searchReqLogId,
        data?.attributeId,
        data?.attributeTypeId
      );
    },
  }
)(App2);
