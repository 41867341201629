import { useEffect, useState } from "react";
import { getSearchLocation2 } from "../services/restSearchService";
import { FixRelativePath, getLocationSocialMedia } from "../utils/utils";
import LocationFake from "../services/json/sampleLocationData.json";

export function useLocation(
  locationId,
  thirdPartyId,
  landingPageId,
  clientId,
  apiKey
) {
  const getAttributeValue = (attributeId, isImage, attributeName = "") => {
    if (locationState.loading) return;

    let attribute;

    if (attributeId)
      attribute = locationState.location.Attributes.find(
        (attr) => attr.AttributeId === attributeId
      );
    else if (attributeName)
      attribute = locationState.location.Attributes.find(
        (attr) => attr.AttributeName === attributeName
      );

    if (attribute) {
      return isImage
        ? FixRelativePath(attribute.AttributeValue)
        : attribute.AttributeValue;
    }
    return "";
  };

  const [locationState, setLocationState] = useState({
    loading: true,
    location: {
      SocialMedia: [],
      Id: 0,
      Name: null,
      Address1: null,
      Address2: null,
      StateAbbreviation: null,
      StateName: null,
      City: null,
      URL: null,
      CountryCode: null,
      Latitude: 0,
      Longitude: 0,
      PostCode: null,
      PhoneNumber: null,
      EmailAddress: null,
      ContactName: null,
      ContactPosition: null,
      ImageFileUrl: null,
      GooglePlaceID: null,
      SEO: {
        MetaDescription: null,
        SEOTitle: null,
      },
      CategoryTree: [
        {
          Data: null,
          ID: 0,
          Name: null,
          SubCategories: [],
          ThirdPartyID: null,
        },
      ],
      Attributes: [
        {
          AttributeDisplayText: null,
          AttributeId: 0,
          AttributeName: null,
          AttributeTypeID: null,
          AttributeValue: null,
        },
      ],
      ComplexAttributes: [
        {
          AttributeId: 0,
          AttributeName: null,
          SubAttributes: [
            {
              AttributeDisplayImage: null,
              AttributeDisplayText: null,
              AttributeId: 0,
              AttributeName: null,
              AttributeTypeID: 0,
              AttributeValue: null,
            },
          ],
        },
      ],
      DailyHoursList: [
        {
          Closed: false,
          ClosingTime: null,
          DayOrder: 0,
          HolidayHours: false,
          HolidayName: null,
          HoursDisplayText: null,
          NameOfDay: null,
          NoHoursAvailable: false,
          OpeningTime: null,
          SpecialHours: false,
        },
      ],
      LandingPageImages: [],
      Coupons: [
        {
          ClientId: 0,
          CouponId: 0,
          CouponImage: null,
          CouponText: null,
          EndDate: null,
          Name: null,
          PromoPageURL: null,
          PromotionName: null,
          StartDate: null,
          ThumbnailImage: null,
        },
      ],
      Testimonials: [],
    },
  });

  useEffect(() => {
    const getLocationAsync = async () => {
      const location = await getSearchLocation2(
        locationId,
        thirdPartyId,
        landingPageId,
        clientId,
        apiKey
      );
      //const location = LocationFake;
      // Fix relative path for images
      LocationFake.ImageFileUrl = FixRelativePath(location.ImageFileUrl);
      LocationFake.SocialMedia = getLocationSocialMedia(location.SocialMedia);

      setLocationState({ loading: false, location: location });
    };

    if (locationId || thirdPartyId) {
      getLocationAsync();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, thirdPartyId, clientId, apiKey]);

  return [locationState, getAttributeValue];
}
