import React from "react";
import PropTypes from "prop-types";
import LabelWithIcon from "../labelWithIcon/labelWithIcon";

/** Component to display some location address including the country and phone
 *  TODO: support for different addres format city, state postal code for different
 *  countries other than USA
 */
const Address = ({
  address1,
  address2,
  city,
  stateAbbr,
  postalCode,
  country,
  phone,
  shortAddress = false,
}) => {
  return (
    <ul className="list-unstyled">
      <li>{address1}</li>
      {!shortAddress && <li>{address2}</li>}
      {city && (
        <li>
          {city}, {stateAbbr} {postalCode}
        </li>
      )}
      {!shortAddress && <li>{country}</li>}
      {!shortAddress && phone && (
        <li>
          <LabelWithIcon
            containerClass="mt-1"
            url={"tel:" + phone}
            title={phone}
            btIcon={{ btIconName: "telephone-outbound-fill", size: 16 }}
          ></LabelWithIcon>
        </li>
      )}
    </ul>
  );
};

Address.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  /** Two letter state or province abbreviation */
  stateAbbr: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
  /** If this prop is present the phone will show as link with tel: */
  phone: PropTypes.string,
};

export default Address;
