import { useEffect, useState } from "react";
import { DoSearch2 } from "../services/restSearchService";

export function useDoSearch2(searchParameters, onSuccess) {
  const [doSearch2State, setdoSearch2State] = useState({
    locations: [],
    doSearch2SearchReqLogId: 0,
    loading: true,
    hasError: false,
  });

  useEffect(() => {
    setdoSearch2State({
      locations: [],
      doSearch2SearchReqLogId: 0,
      loading: true,
    });

    const doSearch2 = async () => {
      let locations = [];
      try {
        locations = await DoSearch2(searchParameters);
        if (locations && locations.ResultList) {
          const results = locations.ResultList;

          setdoSearch2State({
            locations: results,
            doSearch2SearchReqLogId: locations.SearchReqLogId,
            loading: false,
            hasError: false,
          });
        } else {
          //the search return null or give some error.
          setdoSearch2State({
            locations: [],
            doSearch2SearchReqLogId: 0,
            loading: false,
            hasError: true,
          });
        }
      } catch (e) {
        //the search return null or give some error.
        setdoSearch2State({
          locations: [],
          doSearch2SearchReqLogId: 0,
          loading: false,
          hasError: true,
        });
      }

      onSuccess();
    };

    if (
      searchParameters.countryId &&
      (searchParameters.latitude ||
        searchParameters.longitude ||
        searchParameters.state ||
        searchParameters.city ||
        searchParameters.zipCode ||
        searchParameters.radius === 1)
    ) {
      doSearch2();
    }
  }, [searchParameters, onSuccess]);

  return doSearch2State;
}
