/**
 * Get postal code validation pattern.
 * @param {string} countryCode Two-letter country code.
 * @returns {RegExp} A regular expression to validate postal code.
 */
export function getPostalCodePattern(countryCode) {
  const allPatterns = {
    'US': /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
    'CA': /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    'DE': /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/
  }

  return allPatterns[countryCode] ?? /.*/;
}