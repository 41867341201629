import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import PropTypes from "prop-types";

import DynamicInputSelector from "./dynamicInputSelector";
import ValidationsGenerator from "./validationGenerator";

import { useTranslation } from "react-i18next";
import { getLocationList } from "../../services/restSearchService";
import { convertBullseyeDateToISO, convertTimeFrom12To24Format } from "../../utils/utils";
import { sortBy } from "lodash";
import BullseyeLoader from "../bullseyeLoader";
/** Form component, to display forms through a configuration file, with this they are dynamically created
 *  the configuration file has the definition of the inputs and their validations, olso has the submit actions
 *
 */
const DynamicForm = ({
  formLeadConfiguration,
  location,
  title,
  subTitle,
  extraTitle,
  disclamer,
  small = false,
  compact = false,
  GoogleCaptchaKey = "",
  onSubmit,
  onPreview,
  allowPreview = false,
  allowSubmit = true,
  countriesAndStatesConfig,
  extraMessageValidation,
}) => {
  const { response, enableCaptcha } = formLeadConfiguration;

  const { register, setValue: setFormValue, handleSubmit, errors, setError, control, watch } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: location?.Id
      ? {
        locationName: location?.Name,
        Address1: location?.Address1,
        Address2: location?.Address2,
        City: location?.City,
        // Can't set the state value via react-hook-form's API, so we do it manually.
        // CountryId: location?.CountryId,
        // State: location?.State,
        PostalCode: location?.PostCode,
        envent_title: location?.Attributes.find(a => a.AttributeName === "Ceremony Title")?.AttributeValue,
        startDate: convertBullseyeDateToISO(location?.Attributes.find(a => a.AttributeName === "Ceremony Start Date")?.AttributeValue),
        endDate: convertBullseyeDateToISO(location?.Attributes.find(a => a.AttributeName === "Ceremony End Date")?.AttributeValue),
        startTime: convertTimeFrom12To24Format(location?.Attributes.find(a => a.AttributeName === "Ceremony Start Date Time Range")?.AttributeValue),
        endTime: convertTimeFrom12To24Format(location?.Attributes.find(a => a.AttributeName === "Ceremony End Date Time Range")?.AttributeValue),
        description: location?.Attributes.find(a => a.AttributeName === "Ceremony Description")?.AttributeValue,
        website: location?.URL,
        contact_name: location?.ContactName,
        contact_email: location?.EmailAddress,
        host_legion_branch: location?.Attributes.find(a => a.AttributeName === "Host Legion Branch")?.AttributeValue,
      }
    : {}
  });
  // It is not possible to set some values via react-hook-form's API, so we do it manually.
  useEffect(() => {
    if (!location?.CountryId) return;
    
    window.setTimeout(() => {
      // @todo Try setting the value via react-hook-form's API – defaultValues.
      document.querySelector('select[name="CountryId"]').value = location?.CountryId;
      const event = new Event('change', { bubbles: true });
      document.querySelector('select[name="CountryId"]').dispatchEvent(event);

      // Make sure the date is not in the past.
      const endDate = document.querySelector('input[name="endDate"]');
      const currentDate = new Date().toISOString().split("T")[0];
      if (endDate.value < currentDate) {
        const startDate = document.querySelector('input[name="startDate"]');
        const startTime = document.querySelector('input[name="startTime"]');
        const endTime = document.querySelector('input[name="endTime"]');

        startDate.value = '';
        startTime.value = '';
        endDate.value = '';
        endTime.value = '';

        // Show tip message
        const labelNote = document.querySelector('.datetime-range .label-note');
        labelNote.classList.remove('hidden');

      }
    }, 100);
    window.setTimeout(() => {
      setFormValue("StateAbbr", location?.StateAbbr);
    }, 200);
  }, [setFormValue, location]);

  const captchaRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    isValidCaptcha: false,
    errorValidCaptcha: "",
    submited: false,
  });
  const [formFields, setFormFields] = useState([]);

  const onFormSubmit = async (data) => {
    const { isValidCaptcha } = formData;
    if (enableCaptcha && !isValidCaptcha) {
      setFormData({ errorValidCaptcha: "Invalid Captcha" });
      return;
    }
    captchaRef.current && captchaRef.current.reset();
    
    await onSubmit(data);
    setFormData({ submited: true });
  };

  const onFormPreview = async (data) => {
    const { isValidCaptcha } = formData;
    if (enableCaptcha && !isValidCaptcha) {
      setFormData({ errorValidCaptcha: "Invalid Captcha" });
      return;
    }
    captchaRef.current && captchaRef.current.reset();

    await onPreview(data);
  };

  /**
   * Populates host branch field options.
   * @param {string | number} countryId Bullseye country ID.
   */
  const populateHostBranches = async function (branchField, countryId) {
    const categoryIds = process.env.REACT_APP_BRANCH_CATEGORY_IDS.split(',');
    await getLocationList([countryId], categoryIds).then((locations) => {
      const options = locations.map(l => ({label: l.Name, value: l.Id}));
      branchField.options = sortBy(options, [o => o.label.toLowerCase()]);
      // Hash the options to trigger a re-render after the options populated.
      setHostBranchesHash(JSON.stringify(locations).length);
      window.setTimeout(() => { 
        if (location.Id) {
          setFormValue("host_legion_branch", location?.Attributes.find(a => a.AttributeName === "Host Legion Branch")?.AttributeValue);
        }
        else {
          setFormValue("host_legion_branch", '');
        }
        setHostBranchesHash(JSON.stringify(locations).length + 1);
      }, 200);
    });
  }
  // Host branches state. Used to trigger a re-render when the options are populated.
  const [, setHostBranchesHash] = useState('');

  /** onChange Input saves on the State */
  const fieldOnChange = (index, e) => {
    let newArr = [...formFields];
    // For some reason the value is not set when the field is a react-select component.
    if (newArr[index].name === 'host_legion_branch') {
      newArr[index].value = e.value;
      setFormValue(newArr[index].name, newArr[index].value);
    }
    else {
      newArr[index].value = e.target.value;
    }
    setFormFields(newArr);

    // Popupate host branches field upon country change.
    if (e.target && e.target.id === "CountryId") {
      const countryId = e.target.value;
      const branchField = formFields.find(f => f.name === "host_legion_branch")
      populateHostBranches(branchField, countryId).then(() => {
        setFormFields(formFields);
      });
    }
  };

  /** Captcha state */
  function onChangeCaptcha(value) {
    setFormData({
      ...formData,
      isValidCaptcha: value ?? false,
      errorValidCaptcha: "",
    });
  }

  useEffect(() => {
    const loadConfiguration = () => {
      const { fields } = formLeadConfiguration;
      /** create a new array with the new inputs field to display */
      let newArr = [];

      if (formLeadConfiguration) {
        /** read all the fields on the JSON */
        fields.forEach((item) => {
          /** get the rules validation for the field */
          const newValidationItem = ValidationsGenerator(item);

          let required = newValidationItem
            ? item.validations.find((x) => x.rule === "isRequired")
            : false;
          newArr.push({
            required:
              required && required.boolValue ? required.boolValue : false,
            inputOptionName: item.inputOptionName,
            name: item.name ? item.name : item.id.toString(),
            validations: newValidationItem,
            label: item.label,
            placeholder: item.placeholder,
            options: item.options,
            labelNote: item.labelNote ?? "",
          });
        });
      }
      
      if (location?.CountryId) {
        const branchField = newArr.find(f => f.name === "host_legion_branch");
        populateHostBranches(branchField, location.CountryId).then(() => {
          // @todo The host branch field doesn't populate on the first render,
          // it required to change country before there are options.
          // setFormFields([...newArr]);
        });
      }
      setFormFields(newArr);
      setIsLoading(false);
    };
    loadConfiguration();
  }, [formLeadConfiguration]);

  const { t, i18n } = useTranslation();
  const NewRecaptcha = () => {
    return (
      <ReCAPTCHA
        ref={captchaRef}
        className="text-right"
        sitekey={GoogleCaptchaKey}
        onChange={onChangeCaptcha}
        onExpired={() => {
          captchaRef.current.reset();
        }}
        hl={i18n.language}
      />
    );
  };
  const [inputCaptcha, setInputCaptcha] = useState(<NewRecaptcha />);
  useEffect(() => {
    setInputCaptcha(<NewRecaptcha />);
  }, [i18n.language]);

  return isLoading
    ? <BullseyeLoader />
    : <>
      {!isLoading &&
        ((formData.submited && extraMessageValidation?.hasErrors) ||
          !formData.submited) && (
          <div className="w-100">
            <div className="bullseye-red h4">
              {formLeadConfiguration.title
                ? formLeadConfiguration.title
                : title}
            </div>
            {subTitle && <div className="mb-2">{subTitle}</div>}
            {extraTitle && <div>{extraTitle}</div>}
            {disclamer && (
              <div className="alert alert-warning mt-3" role="alert" dangerouslySetInnerHTML={{ __html: disclamer }} />
            )}

            <form>
              {formFields &&
                formFields.map((item, index) => {
                  return (
                    <DynamicInputSelector
                      key={index}
                      compact={compact}
                      small={small}
                      name={item.name}
                      label={item.label}
                      inputOptionName={item.inputOptionName}
                      required={item.required}
                      register={register}
                      watch={watch}
                      control={control}
                      errors={errors}
                      labelNote={item.labelNote ?? ""}
                      placeholder={item.placeholder}
                      options={item.options}
                      onChange={(e) => fieldOnChange(index, e)}
                      validations={item.validations}
                      countriesAndStatesConfig={countriesAndStatesConfig}
                    />
                  );
                })}
              <div className="row">
                <div className="col-sm-8 offset-sm-4">

                {enableCaptcha && (
                  <>
                    {inputCaptcha}
                    <div className="w-100 d-block">
                      <div className="invalid-feedback d-block">
                        {formData.errorValidCaptcha}
                      </div>
                    </div>
                  </>
                )}

                <div className="alert alert-warning">
                  {formLeadConfiguration.previewNotice}
                </div>

                {allowPreview && <Button
                  className="mt-3 mr-3 action-preview"
                  variant="danger"
                  type="submit"
                  onClick={handleSubmit(onFormPreview)}
                >
                  {t("formLead.button_preview")}
                </Button>}
                <Button
                  className="mt-3 action-submit"
                  variant="danger"
                  type="submit"
                  disabled={!allowSubmit}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  {t("formLead.button_submit")}
                </Button>
                </div>
              </div>
            </form>
          </div>
        )}
      {formData.submited && extraMessageValidation?.hasErrors && (
        <div className="alert alert-danger d-block mt-1" role="alert">
          {extraMessageValidation.errorMessage}
        </div>
      )}

      {!isLoading &&
        formData.submited &&
        !extraMessageValidation?.hasErrors && (
          <div className="d-block">{response}</div>
        )}
    </>
};

DynamicForm.propTypes = {
  /** Json file from the WS  */
  formLeadConfiguration: PropTypes.shape({
    /** Tittle Form label */
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    extraTitle: PropTypes.string,
    /** Type of the formSumission the action of the submit */
    //formSubmissionTypeID: PropTypes.number.isRequired,
    /** Message to show on submit */
    //response: PropTypes.string,
    /** if the formSubmissionTypeID=2 this is url where is called after submit */
    //submissionURL: PropTypes.string,
    /** Option is requiered Captcha on the form, if not comming, the default is false */
    enableCaptcha: PropTypes.bool,
  }).isRequired,
  /** Location infomation */
  /** Location Name */
  locationName: PropTypes.string,
  /** Location address */
  locationAddress: PropTypes.string,
  /** Location ID */
  locationId: PropTypes.number,
  /** option to if paint the small form inputs, if not comming, the default is false  */
  small: PropTypes.bool,
  /** option to if paint the conpact form, if not comming, the default is false  */
  compact: PropTypes.bool,
  /** Google Key with permission to Captcha */
  GoogleCaptchaKey: PropTypes.string,
  /** Whether to allow previewing the form */
  allowPreview: PropTypes.bool,
  /** Whether to allow submitting the form */
  allowSubmit: PropTypes.bool,
  /** Form submit callback */
  onSubmit: PropTypes.func,
  /** Form preview callback */
  onPreview: PropTypes.func
};

export default DynamicForm;
