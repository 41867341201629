import http from "./httpService";
// import sampleDataConfig from "../services/json/sampleConfigLocator.json";
const endpointPath = "InterfaceConfiguration";
const getInterfaceConfigurationionGetMethod = "GetInterfaceConfiguration";
const getGeoConfiguration = "GeoConfiguration/ValidateGeoLocation";

function getGeoConfigurationURL(countryId, city, stateAbbr) {
  return `${process.env.REACT_APP_WRAPPER_URL}/${getGeoConfiguration}?countryId=${countryId}&city=${city}&stateAbbr=${stateAbbr}`;
}
function getInterfaceConfigurationURL(interfaceName, apiKeyName, language) {
  return `${
    process.env.REACT_APP_WRAPPER_URL
  }/${endpointPath}/${getInterfaceConfigurationionGetMethod}?interfaceName=${interfaceName}${
    apiKeyName ? "&apiKeyName=" + apiKeyName : ""
  }${language ? "&languageCode=" + language : ""}`;
}
export async function getInterfaceCongif(
  interfaceName,
  apiKeyName = "",
  language
) {
  //return sampleDataConfig;
  const config = await http.get(
    getInterfaceConfigurationURL(interfaceName, apiKeyName, language),
    {
      params: null,
    }
  );
  return config.data;
}
export async function isValidCountryAndState(
  apiKey,
  clientId,
  countryId,
  city,
  stateAbbr
) {
  http.setSecurityParameters(clientId, apiKey);
  const config = await http.get(
    getGeoConfigurationURL(countryId, city, stateAbbr)
  );
  return config.data;
}
