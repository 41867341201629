import React from "react";
import "./slider.scss";

import PropTypes from "prop-types";
/** The header component is full width, where some navigation elements and other content such as the location's logo and image are located.*/
const Slider = ({
  title,
  subTitle,
  website,
  email,
  directions,
  backgroundImage,
  logo,
  backgroundOpacy = 0.5,
}) => {
  return (
    <div
      className="d-flex flex-column justify-content-end slider__main slider__main--container"
      style={{
        backgroundImage: `url(` + backgroundImage + ")",
      }}
    >
      <hr className="w-1 m-0 p-0" />

      <div className="d-flex flex-column flex-md-row slider__main--information">
        {logo && (
          <div className="slider__main--logoContainer">
            <img src={logo} alt={title} style={{ maxWidth: 150 }}></img>
          </div>
        )}
        <div className="pt-1 slider__main--infoContainer">
          <div className="w-100 text-center">
            {title && <span className="h2 d-block">{title}</span>}
            {subTitle && <span className="h5 d-block">{subTitle}</span>}
          </div>

          <div className="slider__iconsArray">
            {website && (
              <>
                <a target="_blank" rel="noopener noreferrer" href={website}>
                  Website
                </a>
                <span className="mx-3 border-right"></span>
              </>
            )}
            {email && (
              <>
                <a href={"mailto:" + email}>Email</a>
                <span className="mx-3 border-right"></span>
              </>
            )}
            {directions && (
              <a target="_blank" rel="noopener noreferrer" href={directions}>
                Directions
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
Slider.propTypes = {
  /** Title of the card */
  title: PropTypes.string.isRequired,
  /**  URL to the website option*/
  website: PropTypes.string,
  /**  URL to the email option*/
  email: PropTypes.string,
  /**  URL to the direction option*/
  directions: PropTypes.string,
  /**  URL to the website option*/
  backgroundImage: PropTypes.string.isRequired,
  /** Logo fo the company */
  logo: PropTypes.string,
  /** Image background to the header */
  backgroundOpacy: PropTypes.number,
};
export default Slider;
