import React, { useState } from "react";
import StartRating from "./startRating";
import CardListRating from "./cardListRating";
import "./reviews.scss";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import WriteReview from "./writeReview";

/** Component to display the information of reviews */
const Reviews = ({
  title,
  listReviews,
  isBullseyeReviews = false,
  onSubmit,
  maxItemsToDisplay = 3,
  reviewConfiguration,
}) => {
  const { rating, countReviews, reviews, urlMoreReviews } = listReviews;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isShowViewFullReviews, setIsShowViewFullReviews] = useState(false);

  const openLightbox = (isShowReviews = false) => {
    setIsShowViewFullReviews(isShowReviews);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };
  let newReviewConfiguration = reviewConfiguration;
  if (!reviewConfiguration) {
    newReviewConfiguration = {
      allowLocationManageReviews: false,
      comments: true,
      overallRating: true,
      ratingCategoryOne: true,
      ratingCategoryThree: true,
      ratingCategoryTwo: true,
      recommendationQuestion: true,
      reviewerName: true,
    };
  }
  return (
    <>
      {reviews && reviews.length > 0 && (
        <div>
          <div className="d-block bullseye-red h4">{title}</div>

          <div className="textHeader mb-1 border-bottom">
            <span className="align-middle d-inline-block">
              <StartRating rating={rating} isTittle={true} />
            </span>
            <span className="align-middle mx-2 px-2">
              <strong>Avg. {rating}</strong>
            </span>
            <span className="align-middle mx-2 px-2 text-muted border-left border-right">
              {countReviews} reviews
            </span>
            {isBullseyeReviews && onSubmit && (
              <span className="align-middle mx-2 px-2 text-muted">
                <button
                  onClick={() => openLightbox(false)}
                  className="btn btn-link"
                >
                  Write a Review
                </button>
              </span>
            )}
          </div>
          <CardListRating
            listRatings={
              reviews.length >= maxItemsToDisplay
                ? reviews.slice(0, maxItemsToDisplay)
                : reviews
            }
            reviewConfiguration={newReviewConfiguration}
          />
          <div className="my-2">
            {!isBullseyeReviews && (
              <a target="_blank" rel="noreferrer" href={urlMoreReviews}>
                Read More Reviews
              </a>
            )}
            {isBullseyeReviews && reviews.length > maxItemsToDisplay && (
              <button
                onClick={() => openLightbox(true)}
                className="btn btn-link"
              >
                Read More Reviews
              </button>
            )}
          </div>
        </div>
      )}
      {isBullseyeReviews && onSubmit && (
        <Modal
          animation={true}
          show={viewerIsOpen}
          onHide={closeLightbox}
          centered
          contentClassName="reviews__modal--content"
          size={isShowViewFullReviews ? "lg" : "md"}
        >
          <Modal.Header closeButton>
            <div className="d-block bullseye-red h4">{title}</div>
          </Modal.Header>
          <Modal.Body>
            {isShowViewFullReviews && (
              <div className="p-3">
                <CardListRating
                  listRatings={reviews}
                  reviewConfiguration={newReviewConfiguration}
                />
              </div>
            )}
            {!isShowViewFullReviews && (
              <WriteReview
                onSubmit={onSubmit}
                reviewConfiguration={newReviewConfiguration}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
Reviews.propTypes = {
  /** Title to display on the header component */
  title: PropTypes.string.isRequired,
  listReviews: PropTypes.shape({
    /** AVG rating number of the review */
    rating: PropTypes.number.isRequired,
    /** count of the reviews */
    countReviews: PropTypes.number.isRequired,
    /** url external to show more reviews */
    urlMoreReviews: PropTypes.string,
    /** reviws configurations */
    reviewConfiguration: PropTypes.shape({
      allowLocationManageReviews: PropTypes.bool.isRequired,
      comments: PropTypes.bool.isRequired,
      overallRating: PropTypes.bool.isRequired,
      ratingCategoryOne: PropTypes.bool.isRequired,
      ratingCategoryThree: PropTypes.bool.isRequired,
      ratingCategoryTwo: PropTypes.bool.isRequired,
      recommendationQuestion: PropTypes.bool.isRequired,
      reviewType: PropTypes.bool.isRequired,
      reviewerName: PropTypes.bool.isRequired,
    }),
    /** List of reviews */
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        /** Name of the person who made the review */
        author_name: PropTypes.string.isRequired,
        /** rating of the review */
        rating: PropTypes.number.isRequired,
        /** description of the time when was made the review */
        relative_time_description: PropTypes.string.isRequired,
        /** description text of the review */
        text: PropTypes.string.isRequired,
        /** List of ratings services if there was a list  */
        services: PropTypes.arrayOf(
          PropTypes.shape({
            /** name of the list to rate */
            name: PropTypes.string.isRequired,
            /** rating of this list */
            rating: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
  /**flag to know if the review is from BullsEye or Google */
  isBullsEyeReviews: PropTypes.bool,
  /** fot BullsEye reviews, the function to reterun data of a new review */
  onSubmit: PropTypes.func,
};
export default Reviews;
