import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "lazysizes";
import PropTypes from "prop-types";

/** Image component to display in Carusel shape */
const MultiCarousel = ({ photos, title, itemNumber, containerExtraClass }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2048 },
      items: Math.ceil(itemNumber * 1.5),
    },
    desktop: {
      breakpoint: { max: 2048, min: 1024 },
      items: itemNumber,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: Math.ceil(itemNumber * 0.5),
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: Math.ceil(itemNumber * 0.25),
    },
  };

  return (
    <div>
      <div className="bullseye-red h4">{title}</div>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        infinite={false}
        autoPlaySpeed={5000}
        containerClass="carousel-container"
        itemClass="carousel-item"
      >
        {photos.map((item) => {
          return (
            <div key={item.id} className={containerExtraClass}>
              <img
                className="lazyload img-fluid"
                data-src={item.imageURL}
                alt={item.alternativeText}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
MultiCarousel.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      /** Item id */
      id: PropTypes.string.isRequired,
      /** Title of the image */
      titleImage: PropTypes.string,
      /** Url image */
      imageURL: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Title of the card */
  title: PropTypes.string.isRequired,
  /** Number of images to display in carousel at the same time */
  itemNumber: PropTypes.number.isRequired,
  /** css class to container if needed */
  containerExtraClass: PropTypes.string,
};
export default MultiCarousel;
