import React from "react";
import Label from "../label/label";
import Select from "react-select";
import ErrorDisplay from "../errorDisplay/errorDisplay";
import { Controller } from 'react-hook-form';
import "./autocompleteInput.scss";
const AutocompleteInput2 = ({
  name,
  label,
  small = false,
  compact = false,
  register,
  control,
  errors,
  placeholder = "",
  required = false,
  options,
  ...props
}) => {
  return (
    <div className="form-group row">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <div
        className={`react-select py-0 col-sm-8 ${errors[name] ? "is-invalid" : ""} `}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={(field) => {
            return (
              <Select
                options={options}
                value={options.find(option => option.value + '' === field.value + '') ?? null}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={field.ref}
                placeholder={!compact ? placeholder : label}
                {...props}
              />
            )
          }}
        />
      </div>
      {errors[name] && (
        <ErrorDisplay errorMessage={errors[name]?.message} />
      )}
    </div>
  );
};
export default AutocompleteInput2;
