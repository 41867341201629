import React from "react";
import { dateToStringFormat, datesAreEquals } from "../../utils/utils";
import "./events.scss";

import PropTypes from "prop-types";
const Events = ({
  title,
  eventList,
  maxItemsToDisplay = 3,
  showDateLikeDescription = false,
}) => {
  return (
    <div>
      <div className="bullseye-red h4">{title}</div>
      {eventList && eventList.length > 0 && (
        <ul className="list-unstyled">
          {eventList.slice(0, maxItemsToDisplay).map((item) => (
            <li className="media border-bottom mt-3 pb-3" key={item.EventId}>
              <div className="card event__cardDate border-bullseye mr-3">
                <div className="bullseye-red card-body text-center py-2">
                  <div
                    className={
                      item.EndDate &&
                      !datesAreEquals(item.StartDate, item.EndDate)
                        ? "event__twoDate mt-1"
                        : "event__oneDate mt-3"
                    }
                  >
                    {dateToStringFormat(item.StartDate, "MMM DD").toUpperCase()}
                  </div>
                  {item.EndDate &&
                    !datesAreEquals(item.StartDate, item.EndDate) && (
                      <>
                        <div className="event__to">TO</div>
                        <div className="event__twoDate">
                          {dateToStringFormat(
                            item.EndDate,
                            "MMM DD"
                          ).toUpperCase()}
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div className="media-body">
                {!showDateLikeDescription && (
                  <>
                    <strong>{item.EventTitle}</strong>
                    <div>{item.EventDescription}</div>
                    {item.RegistrationURL && (
                      <a alt={item.EventTitle} href={item.RegistrationURL}>
                        View more info
                      </a>
                    )}
                  </>
                )}
                {showDateLikeDescription && (
                  <>
                    <div>
                      <strong>
                        {dateToStringFormat(
                          item.StartDate,
                          "dddd, MMMM D, YYYY"
                        )}
                      </strong>
                      {` ${dateToStringFormat(
                        item.StartDate,
                        "h:mm a"
                      ).toUpperCase()} ${item.EndDate ? " to " : ""} `}
                    </div>
                    {item.EndDate && (
                      <div>
                        <strong>
                          {dateToStringFormat(
                            item.EndDate,
                            "dddd, MMMM D, YYYY"
                          )}
                        </strong>
                        {` ${dateToStringFormat(
                          item.EndDate,
                          "h:mm a"
                        ).toUpperCase()} `}
                      </div>
                    )}
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
Events.propTypes = {
  /** Title of the card */
  title: PropTypes.string.isRequired,
  /** List of events to display */
  eventList: PropTypes.arrayOf(
    PropTypes.shape({
      /** Event id */
      EventId: PropTypes.number.isRequired,
      /** Begin date of the event */
      StartDate: PropTypes.string.isRequired,
      /** End date of the event */
      EndDate: PropTypes.string,
      /** Title */
      EventTitle: PropTypes.string.isRequired,
      /** Description of the Event */
      EventDescription: PropTypes.string.isRequired,
      /** Url for more info */
      RegistrationURL: PropTypes.string,
    })
  ).isRequired,
  /** Max items to display */
  maxItemsToDisplay: PropTypes.number,
};
export default Events;
