import React from "react";
import "./socialMediaIcons.scss";
import PropTypes from "prop-types";
/** Component to display social media icons with their own link address */
const SocialMediaIcons = ({ icons, title }) => {
  return (
    icons.length > 0 && (
      <div>
        {title && <div className="bullseye-red h4">{title}</div>}
        <ul className="list-inline mb-0">
          {icons.map((icon) => {
            return (
              <li key={icon.name} className="list-inline-item social-icon">
                <a
                  href={icon.url}
                  alt={icon.name}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={icon.name}
                >
                  <svg className="socialMedia__icon-md">
                    <use href={`/images/social-media-icons.svg#${icon.name}`} />
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

SocialMediaIcons.propTypes = {
  /** Title of the card */
  title: PropTypes.string,
  /** object with the icon information */
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      /**name of the boostrap icon */
      name: PropTypes.string.isRequired,
      /** Url to the link icon */
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default SocialMediaIcons;
