import http from "./httpService";

const endpointPath = "RestLocation.svc";

export async function addLocation(dataPost) {
  http.setSecurityParameters2(dataPost.ClientId, dataPost.ApiKey);
  const location = await http.post(
    `${endpointPath}/AddLocation`, 
    dataPost
  );
  return location.data;
}

export async function updateLocation(dataPost) {
  http.setSecurityParameters2(dataPost.ClientId, dataPost.ApiKey);
  const location = await http.post(
    `${endpointPath}/UpdateLocation`, 
    dataPost
  );
  return location.data;
}

/**
 * Gets a list of locations associated with a user.
 * Loads active locations by default but can include inactive locations.
 * 
 * @param {string} username
 * @param {string} includeInactive
 * @returns {object} User locations
 */
export async function GetUserLocationList(username, includeInactive) {
  http.setSecurityParameters2();
  const config = {params: {username, includeInactive}};
  return await http.get(`${endpointPath}/GetUserLocationList`, config);
}