import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ seoTitle, seoMetaDescription }) => {
  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoMetaDescription} />
      </Helmet>
    </>
  );
};

export default SEO;
