export const CONFIG_STAGING = {
  CATEGORY_LEGION_BRANCH_LOCATION: 29604,
  CATEGORY_LEGION_REMEMBRANCE_DAY_LOCATION: 29607,
  CATEGORY_LEGION_COMMAND: 29603,
  CATEGORY_LEGION_BRANCH_PO_BOX: 29605,
  ATTRIBUTES: {
    SUBMITTER_NAME: 11721,
    SUBMITTER_EMAIL: 11722,
    CELEBRATION_TITLE: 11723,
    CELEBRATION_START_DATE: 11724,
    CELEBRATION_END_DATE: 11725,
    CELEBRATION_START_DATE_TIME: 11726,
    CELEBRATION_END_DATE_TIME: 11727,
    CELEBRATION_DESCRIPTION: 11728,
    HOST_LEGION_BRANCH: 11729,
  },
};
export const CONGIF_PRODUCTION = {
  CATEGORY_LEGION_BRANCH_LOCATION: 84175,
  CATEGORY_LEGION_REMEMBRANCE_DAY_LOCATION: 105916,
  CATEGORY_LEGION_COMMAND: 84174,
  CATEGORY_LEGION_BRANCH_PO_BOX: 84176,
  ATTRIBUTES: {
    SUBMITTER_NAME: 3410,
    SUBMITTER_EMAIL: 3411,
    CELEBRATION_TITLE: 3418,
    CELEBRATION_START_DATE: 3413,
    CELEBRATION_END_DATE: 3417,
    CELEBRATION_START_DATE_TIME: 3416,
    CELEBRATION_END_DATE_TIME: 3415,
    CELEBRATION_DESCRIPTION: 3414,
    HOST_LEGION_BRANCH: 3412,
  },
};
export const DOMAIN_LANGUAGE = {
  localhost: "fr",
  "legion-remembranceday.bullseyelocations-staging": "en",
};
